import env from "./env";
const AUTH_TOKEN_INFO_KEY = "Auth Token Info";
const AUTH_TOKEN_KEY = "Auth Token";

/**
 * Sets a refresh token if not found, and requests a new auth token if the
 * current one has expired.
 * @returns an authentication token for use in headers
 */
export const getAuthToken = async () => {
  try {
    const authTokenInfo = sessionStorage.getItem(AUTH_TOKEN_INFO_KEY);
    if (!authTokenInfo) {
      console.log("Not authorized. Please sign in.");
      // Print stack for debugging.
      //console.error((new Error()).stack)
      return false;
    }
    const blob = JSON.parse(authTokenInfo);
    console.log("Expire blob = " + blob);
    const expireDate = new Date(blob.expireDate);
    console.log("Expire date = " + expireDate);
    const now = new Date();
    console.log("Now = " + now);
    if (now > expireDate) {
      // expire date is in the past
      console.log("Request new id token from server");
      const url =
        "https://securetoken.googleapis.com/v1/token?key=" +
        env.REACT_APP_FIREBASE_API_KEY;
      let authToken = false;
      const result = await fetch(url, {
        method: "POST",
        body: "grant_type=refresh_token&refresh_token=" + blob.refreshToken,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const parsedResult = await result.json();
      console.log("parsedResult from refresh");
      console.log(parsedResult);
      if (401 == result.status) {
        alert("Could not authenticate. Please log in.");
      }
      if (parsedResult.refresh_token) {
        await setRefreshToken(parsedResult);
        await setAuthToken(parsedResult.id_token);
        authToken = parsedResult.id_token;
      }
      return authToken;
    } else {
      console.log("utils getAuthToken 1 return Auth Token");
      return sessionStorage.getItem("Auth Token");
    }
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * Stores refresh_token and an expire date for the id token
 * @param {*} jsonObject the JSON Object returned when logging in
 * @returns true
 */
export const setRefreshToken = async (jsonObject) => {
  const blob = {};
  // nMillis is 3600, use 20 for testing so 20 seconds
  // And just take off a few seconds to avoid race conditions
  const nMillis = 3590;
  // const nMillis = 20
  blob.expireDate = Date.now() + nMillis * 1000;
  blob.refreshToken = jsonObject.refreshToken;
  const cookieString = JSON.stringify(blob);
  console.log("utils setRefreshToken " + cookieString);
  sessionStorage.setItem(AUTH_TOKEN_INFO_KEY, cookieString);
  return true;
};

/**
 * Sets authentication token for use in headers
 * @param {*} idToken authentication token, a long string like "eyJh..."
 * @returns true
 */
export const setAuthToken = async (idToken) => {
  sessionStorage.setItem(AUTH_TOKEN_KEY, idToken);
  return true;
};
