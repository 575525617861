import { useState } from "react";
import { Link as ReactLink } from "react-router-dom";

import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RolesDropdown from "../../components/admin/RolesDropdown";
import Switch from "@mui/material/Switch";
import { REACT_APP_WEB_BASE_DOMAIN_STAGE, REACT_APP_WEB_BASE_DOMAIN_PRODUCTION} from "../../env"

export default function BasicTextFields({
  title,
  setPassword,
  setEmail,
  setRole,
  roles,
  handleAction,
  handleKeyDown,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const environment = window.localStorage.getItem("environment") || "stage";

  const setEnvironment = () => {
    window.localStorage.setItem(
      "environment",
      environment === "stage" ? "production" : "stage"
    );
    const newEnvironment = window.localStorage.getItem("environment");
    let redirectUrl = (newEnvironment == 'production') ? "https://"+ REACT_APP_WEB_BASE_DOMAIN_PRODUCTION : "https://"+ REACT_APP_WEB_BASE_DOMAIN_STAGE;
    window.location.href = redirectUrl;
  };

  return (
    <div className="login">
      <div className="container-header">
        <div className="logo"></div>
        {title !== "Register" && (
          <div className="login-title">WELCOME BACK!</div>
        )}
        {title === "Register" && <div className="login-title">REGISTER</div>}
      </div>

      <Box
        component="form"
        sx={{
          m: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          color="success"
          id="email"
          label="Email Address"
          required
          variant="outlined"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: "400px" }}
        />
        <TextField
          color="success"
          id="password"
          label="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          required
          sx={{ mt: 2, width: "400px", input: { color: "red" } }}
          onKeyDown={handleKeyDown}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          sx={{ mt: 2, mb: 2, width: "400px", bgcolor: "green" }}
          onClick={handleAction}
        >
          {title == "Register" ? "Register" : "Login"}
        </Button>
        {title !== "Register" && (
          <ReactLink to="/forgot-password" variant="body2">
            Forgot password?
          </ReactLink>
        )}
        {title !== "Register" && (
          <>
            <Link href="/register" variant="body2" sx={{ m: 3 }}>
              {"First time? Register Here"}
            </Link>
            { environment !== "production" && (
            <Box display="flex" alignItems="center">
              <Box component="span" width="180px">
                Environment: {environment === "stage" ? "Staging" : "Production"}
              </Box>
              <Switch
                checked={environment === "production"}
                inputProps={{ "aria-label": "Switch demo" }}
                onChange={setEnvironment}
                sx={{
                  width: '65px',
                  height: '45px',
                  padding: '8px',
                  '& .MuiButtonBase-root.MuiSwitch-switchBase': {
                    padding: '10px',
                  },
                  '& .MuiSwitch-thumb': {
                    color: '#ffffff',
                    width: '24px',
                    height: '24px',
                  },
                  '& .MuiSwitch-track': {
                    borderRadius: '15px',
                  },
                  '& .Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#25d92e !important',
                    opacity: '1 !important',
                  },
                }}
              />
            </Box>
            )}
          </>
        )}
        {title === "Register" && roles && roles.length && (
          <Link href="/login" variant="body2">
            Already registered? Login
          </Link>
        )}
      </Box>
    </div>
  );
}
