import moment from "moment";

export const openFileInNewWindow = (file) => {
  const url = `${process.env.REACT_APP_NORMANS_NURSERY_IMAGE_API_BASE_URL}?i=${file}`;
  let newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const getUserPermissions = (user) => {
  let viewCartPermission = false;

  if (user?.data?.role == "admin" || user?.data?.role == "tagging") {
    viewCartPermission = true;
  }

  return {
    viewCartPermission,
  };
};

export const filterPlantsPhotos = (plantPhotos, filters) => {
  let newPlantPhotos = [...plantPhotos];

  if (filters.yard) {
    newPlantPhotos = newPlantPhotos.filter(
      (plantPhoto) => plantPhoto.yard === filters.yard
    );
  }

  if (filters.startDate || filters.endDate) {
    newPlantPhotos = newPlantPhotos.filter((plantPhoto) => {
      const picCreatedDate = moment(plantPhoto.createdDate);

      if (filters.startDate && filters.endDate) {
        return (
          picCreatedDate.isSameOrAfter(moment(filters.startDate)) &&
          picCreatedDate.isSameOrBefore(moment(filters.endDate))
        );
      }

      if (filters.startDate) {
        return picCreatedDate.isSameOrAfter(moment(filters.startDate));
      }

      if (filters.endDate) {
        return picCreatedDate.isSameOrBefore(moment(filters.endDate));
      }
    });
  }

  if (filters.sort) {
    newPlantPhotos = newPlantPhotos.sort((a, b) => {
      if (a.createdDate?._seconds && b.createdDate?._seconds) {
        if (filters.sort === "newest") {
          return b.createdDate._seconds - a.createdDate._seconds;
        }

        return a.createdDate._seconds - b.createdDate._seconds;
      }

      const aCreatedDate = new Date(a.createdDate).getTime();
      const bCreatedDate = new Date(b.createdDate).getTime();
      if (filters.sort === "newest") {
        return bCreatedDate - aCreatedDate;
      }

      return aCreatedDate - bCreatedDate;
    });
  }

  return newPlantPhotos;
};
