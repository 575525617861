import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { useFetch } from "../../hooks/useFetch";
import { baseUrl } from "../../_constants";
import Header from "../Header";
import Users from "./Users";
import Plants from "./Plants";
import YardsEmails from "./YardsEmails";
import PlantImages from "../PlantImages";
import Footer from "../Footer";

const ENUM_USERS = 0;
const ENUM_PLANTS = 1;
const ENUM_PLANTS_IMAGES = 2;
const ENUM_YARD_EMAILS = 3;

export default function Admin({ user }) {
  const [activeTabValue, setActiveTabValue] = useState(ENUM_USERS);
  const { data: yards } = useFetch(`${baseUrl}/yards`);
  const { data: yardsEmails } = useFetch(`${baseUrl}/yards-emails`);
  const { data: roles } = useFetch(`${baseUrl}/roles`);
  const { data: plants, status: plantsStatus } = useFetch(`${baseUrl}/plants`);
  const { data: usersData } = useFetch(`${baseUrl}/admin/list-users`, {
    Authorization: `Bearer ${sessionStorage.getItem("Auth Token")}`,
  });
  const onActiveTabValueChange = (e, newValue) => setActiveTabValue(newValue);

  return (
    <>
      <div className="container">
        <Header user={user} />
        <div className="title">Manage</div>
        <p></p>
        <Tabs
          value={activeTabValue}
          onChange={onActiveTabValueChange}
          aria-label="basic tabs example"
          indicatorColor="transparent"
        >
          <Tab
            label="Users"
            className="admin-tab"
            id={`tab-value-${ENUM_USERS}`}
          />
          <Tab
            label="Plants"
            className="admin-tab"
            id={`tab-value-${ENUM_PLANTS}`}
          />
          <Tab
            label="Plant Images"
            className="admin-tab"
            id={`tab-value-${ENUM_PLANTS_IMAGES}`}
          />
          <Tab
            label="Yard Email Assignments"
            className="admin-tab"
            id={`tab-value-${ENUM_YARD_EMAILS}`}
          />
        </Tabs>
        {activeTabValue === ENUM_USERS && (
          <Users
            yards={yards}
            roles={roles}
            usersData={usersData}
            user={user}
          />
        )}
        {activeTabValue === ENUM_PLANTS && plantsStatus === "fetched" && (
          <Plants plants={plants} />
        )}
        {activeTabValue === ENUM_PLANTS_IMAGES &&
          plantsStatus === "fetched" && (
            <PlantImages yards={yards} plants={plants} />
          )}
        {activeTabValue === ENUM_YARD_EMAILS && (
          <YardsEmails
            yardsEmails={yardsEmails}
          />
        )}
      </div>
      <Footer />
    </>
  );
}
